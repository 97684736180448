import { render, staticRenderFns } from "./AssetMoveConflict.vue?vue&type=template&id=988a64ea&scoped=true&"
import script from "./AssetMoveConflict.vue?vue&type=script&lang=ts&"
export * from "./AssetMoveConflict.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "988a64ea",
  null
  
)

export default component.exports