










































































































import { Component, Vue } from "vue-property-decorator";
import AssetTable from "@/components/asset/AssetTable.vue";
import Search from "@/components/reusable/Search.vue";
import Pagination from "@/components/reusable/table/Pagination.vue";
import Icon from "@/components/reusable/Icon.vue";
import {
  AssetModel,
  AssetModelRequest,
  AssetRequestOptions,
} from "@/models/asset";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import UIkit from "uikit";
import AssetService from "@/services/asset_service";
import { EventBus } from "@/events/index";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { namespace } from "vuex-class";
import {
  AssetConflictError,
  AuthError,
  ConflictError,
} from "@/services/error_service";
import { APIResponse } from "@/models/api_res";
import {
  convertToSlug,
  formatAssetCrumb,
  filenameRegex,
} from "@/utility/helpers";
import MoveItem from "@/components/reusable/MoveItem.vue";
import AssetMoveConflict from "./AssetMoveConflict.vue";
@Component({
  components: {
    AssetTable,
    Search,
    Pagination,
    Icon,
    MoveItem,
    AssetMoveConflict,
  },
})
export default class Asset extends Vue {
  protected assetService = new AssetService();
  protected moveData: AssetModel[] = [];
  protected moveDestination = {} as AssetModel;
  protected deleted = true;
  protected failed: string[] = [];
  protected conflicted: string[] = [];
  protected success: string[] = [];
  protected deleteData: any[] = [];
  protected toast = false;
  protected convertToSlug = convertToSlug;
  protected filenameRegex = filenameRegex;
  protected formatAssetCrumb = formatAssetCrumb;
  protected breadcrumbs: AssetModel[] = [];
  protected messageHtml = "";
  protected assets: any[] = [{}];
  protected disableDrag = true;
  protected pages = 1;
  protected currentPage = 1;
  protected query = "";
  protected currentDestination = "/assets";
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  created() {
    this.setLoading(true);
    if (this.$route.query.q) {
      this.disableDrag = true;
    }
    this.readUrl();
    this.getBreadCrumbs();
  }

  protected readUrl(): string {
    const options: AssetRequestOptions = this.getRequestOptions();
    const urlPieces = this.$route.path.split("/");
    urlPieces.splice(0, 2); // remove first 2 items to get our params
    let url = "/";
    if (urlPieces.length > 0) {
      if (urlPieces.length === 1) {
        url = "/" + urlPieces[0];
      } else {
        url = urlPieces.join("/");
      }
      this.getAssets({ ...options, path: "/" + url });
    } else {
      this.getAssets(options);
    }
    return url;
  }

  protected async showDeleteToast(): Promise<void> {
    this.showToast(
      `${decodeURIComponent(
        this.$route.query.deleted as string
      )} asset(s) deleted successfully.`
    );
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: AssetModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "asset" },
        { height: "auto", adaptive: true, classes: "overflow" }
      );
    });
    EventBus.$on(
      "conflictResolution",
      (
        action: "skip" | "replace" | "copy",
        item: AssetModel,
        conflictItem: AssetModel,
        final: boolean,
        id: number
      ) => {
        switch (action) {
          case "skip": {
            if (final) {
              const options: AssetRequestOptions = this.getRequestOptions();
              this.getAssets(options);
            }
            break;
          }
          case "replace": {
            this.deleteRequest(
              conflictItem.id!,
              conflictItem.display_name
                ? conflictItem.display_name
                : conflictItem.filename,
              false,
              false
            )
              .then(() => this.saveExisting(item, id, false, final))
              .then(() => {
                return final;
              })
              .then((final: boolean) => {
                const options: AssetRequestOptions = this.getRequestOptions();
                this.getAssets(options);
              });
            break;
          }
          case "copy": {
            const newFileName = this.renameFile(item.filename);
            const newDisplayName = this.renameFile(
              item.display_name ? item.display_name : item.filename
            );
            const itemCopy = {
              ...item,
              filename: newFileName,
              display_name: newDisplayName,
            };
            this.saveExisting(itemCopy, id, false, final)
              .then(() => {
                return final;
              })
              .then((final: boolean) => {
                const options: AssetRequestOptions = this.getRequestOptions();
                this.getAssets(options);
              });
            break;
          }
        }
      }
    );
  }

  protected renameFile(strToSearch: string): string {
    const strToFind = ".";
    const strToInsert = "_copy";
    const n = strToSearch.lastIndexOf(strToFind);
    if (n < 0) return strToSearch + "_copy";
    return strToSearch.substring(0, n) + strToInsert + strToSearch.substring(n);
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    EventBus.$off("conflictResolution");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }

  protected async getAssets(
    optionsObject?: AssetRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.assetService.getAssets(optionsObject);
      this.currentDestination =
        optionsObject && optionsObject.path ? optionsObject.path : "/assets";
      this.assets = res.results;
      this.pages = res.meta.total_pages;
      this.currentPage = res.meta.page;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <span>${err.message}</span>
    </div>
  </div>`
        );
      }
    }
  }

  /**
   * Paginate method triggered by child <Pagination> component.
   * First, calls this.getRequestOptions() to retain any other options (like query term)
   * NOTE: page property in returned options object must be overwritten -- this.getRequestOptions will return the current page.
   *
   * Second, send options object into API get request to get appropriate page of data.
   *
   * Finally, add page query to url if it is not already there. This ensure the correct page query is retained and will fetch correct data if user refreshes.
   */
  protected paginate(page: number) {
    const options: AssetRequestOptions = this.getRequestOptions();
    options.page = page;
    this.getAssets(options);
    if (!this.$route.query.page || this.$route.query.page !== page.toString()) {
      this.$router.push({
        query: { ...this.$route.query, page: page.toString() },
      });
    }
  }

  protected async postNew(
    req: AssetModelRequest,
    final = false
  ): Promise<void> {
    try {
      const res = await this.assetService.createNewAsset(req);
      if (final) {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <strong><span>File(s) have been uploaded successfully.</span> </strong>
    </div>
  </div>`
        );
        this.refreshPage();
      }
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof ConflictError) {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <strong><span>${req.filename} upload failed. Filename already exists - please rename and try again.</span> </strong>
    </div>
  </div>`
        );
      } else {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <strong><span>${req.filename} upload failed.</span> </strong>
    </div>
  </div>`
        );
      }
      if (final) {
        this.refreshPage();
      }
    }
  }

  public refreshPage(): void {
    this.readUrl();
  }

  public receiveSearchTerm(query: string): void {
    if (this.query !== query) {
      this.query = query;
      this.$router.push({ path: "/asset", query: { q: query } });
    }
  }

  /** Reset search by re-requesting unfiltered data */
  public reset(): void {
    this.query = "";
    const options = { ...this.$route.query };
    delete options.q;
    this.$router.push({ query: { ...options } });
  }

  /**
   * Checks URL for current query terms. This should be called on every get request unless user is requesting unfiltered data
   * The object returned varies based on the endpoint being accessed. See model for each request for more details.
   */
  protected getRequestOptions(): AssetRequestOptions {
    const optionsObject = {} as AssetRequestOptions;
    if (this.query || this.$route.query.q) {
      this.query = this.$route.query.q
        ? (this.$route.query.q as string)
        : this.query;
      optionsObject.q = this.query;
    }
    if (this.$route.query.page) {
      optionsObject.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.path) {
      optionsObject.path = this.$route.query.path as string;
    }
    if (this.$route.query.hide) {
      if (!this.$route.query.hide.length) {
        optionsObject.hide = [this.$route.query.hide] as string[];
      } else {
        optionsObject.hide = this.$route.query.hide as string[];
      }
    }
    return optionsObject;
  }

  /**
   * Navigation logic for ascending category tree
   *
   * If there is only 1 item in breadcrumbs, fetch unfiltered top level categories
   *
   * If there are more than 1 breadcrumbs, route to the url in the breadcrumb at [currentindex-1] (or [len-2])
   * Then, logic in methods called in created() & readUrl() takes care of what to render (triggers component rerender)
   */
  protected goBack() {
    const len = this.breadcrumbs.length;
    if (len === 1) {
      this.$router.push("/asset");
    } else {
      const item = this.breadcrumbs[len - 2];
      this.$router.push("/asset/assets" + item);
    }
  }

  /**
   * Build breadcrumbs
   *
   * Get URL path, split on '/', and remove first 2 itmes (static part of URL)
   * @example
   * http://www.website.com/asset/assets/downloads/new --> ['assets', 'downloads', 'new']
   * @
   *
   * Then, call rebuildUrls method to build each breadcrumb url. See method for more info.
   *
   */
  protected getBreadCrumbs(): void {
    const urlPieces = this.$route.path.split("/");
    urlPieces.splice(0, 3); // remove first 3 items to get our params
    const urls = this.rebuildUrls(urlPieces);

    urls.forEach(async (url, index) => {
      if (url !== "/") {
        Vue.set(this.breadcrumbs, index, url);
      }
    });
  }

  protected async sendMoveRequest(
    newParent: string,
    id: number,
    name: string,
    origin: AssetModel,
    final = false
  ): Promise<void> {
    this.setLoading(true);
    const newItem = { filename: origin.filename, path: newParent };
    try {
      await this.assetService.saveAsset(newItem, id);

      if (final) {
        EventBus.$emit(
          "showNotification",
          `  <div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <strong><span>File(s) have been moved successfully.</span> </strong>
    </div>
  </div>`
        );
        this.refreshPage();
      }
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof AssetConflictError) {
        this.$modal.show(
          AssetMoveConflict,
          {
            item: newItem,
            conflictItem: err.details.existing_asset,
            final: final,
            id: id,
          },
          { height: "auto", adaptive: true, classes: "overflow" }
        );
      } else {
        if (final) {
          this.refreshPage();
        }
      }
    }
  }

  protected setDestination(value: AssetModel) {
    this.moveDestination = value;
  }

  protected move(data: AssetModel[]) {
    this.moveData = data;
    UIkit.modal(document.getElementById("move-modal") as HTMLElement).show();
  }

  /**
   * @param strings array of url pieces.
   * Rebuilds and returns array of strings into urls to match path of each category as it descends down tree
   *
   * @return urls
   *
   * @example
   * ['assets', 'downloads', 'new']--> ['/assets', '/assets/downloads', 'assets/downloads/new']
   *
   */
  protected rebuildUrls(strings: string[]): string[] {
    if (strings.length <= 1) {
      return ["/" + strings];
    }
    const urls = ["/" + strings[0]];
    for (let i = 1; i < strings.length; i++) {
      urls.push(urls[i - 1] + "/" + strings[i]);
    }
    return urls;
  }
  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false,
    showToast = true
  ): Promise<void> {
    this.setLoading(true);
    try {
      await this.assetService.deleteAsset(id);
      this.setLoading(false);
      if (final) {
        EventBus.$emit(
          "showNotification",
          `  <div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <strong><span>File(s) have been deleted successfully.</span> </strong>
    </div>
  </div>`
        );
      }
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <strong><span>${name} failed to delete. Please try again.</span> </strong>
    </div>
  </div>`
        );
      }
    }
    if (final && showToast) {
      this.readUrl();
    }
    return;
  }

  protected async saveExisting(
    reqObj: AssetModelRequest,
    id: number,
    showToast = true,
    final?: boolean
  ): Promise<void> {
    try {
      await this.assetService.saveAsset(reqObj, id as number);
      if (showToast) {
        EventBus.$emit(
          "showNotification",
          `  <div class="other-class uk-animation-slide-top uk-alert-success" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-success">
      <strong><span>${reqObj.display_name} has been updated successfully.</span> </strong>
    </div>
  </div>`
        );
        this.readUrl();
      }
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof AssetConflictError) {
        this.$modal.show(
          AssetMoveConflict,
          {
            item: reqObj,
            conflictItem: err.details.existing_asset,
            final: final,
            id: id,
          },
          { height: "auto", adaptive: true, classes: "overflow" }
        );
      } else {
        EventBus.$emit(
          "showNotification",
          `<div class="other-class uk-animation-slide-top uk-alert-danger" uk-alert>
    <a class="uk-alert-close" uk-close @click="$emit('close')"></a>
    <div class="uk-alert-danger">
      <span>${err.message}</span>
    </div>
  </div>`
        );
      }
    }
    return;
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected showToast(msg: string): void {
    this.messageHtml = msg;
    this.toast = true;
  }

  protected openFileManager(): void {
    (this.$refs.fileInput as HTMLInputElement).click();
  }
  protected selectFile(): void {
    this.setLoading(true);
    let path = this.readUrl();
    if (path !== "/") {
      path = decodeURI("/" + path);
    } else {
      path = "/assets";
    }
    const files = (this.$refs.fileInput as HTMLInputElement).files!;
    Array.from(files).map((file, index) => {
      // overwrite readonly property "name" of file object with formatted text
      Object.defineProperty(file, "name", {
        value: filenameRegex(convertToSlug(file.name)),
        writable: true,
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let content;
        if (reader.result) {
          
          if ((reader.result as string).includes("data:application")) {
            content = (reader.result as string).replace(
              /^data:application\/[a-z]+;base64,/, // removing prepended info
              ""
            );
          } else {
            content = (reader.result as string).replace(
              /^data:image\/[a-z]+;base64,/, // removing prepended info
              ""
            );
          }
          if (index === files.length - 1) {
            this.sendRequest(
              {
                filename: file.name,
                content: content,
                path: path,
              },
              true
            );
          } else {
            this.sendRequest({
              filename: file.name,
              content: content,
              path: path,
            });
          }
        }
      };
    });
  }

  protected addFolder(): void {
    const folder = prompt("Please enter folder name below", "New Folder");
    if (folder != null) {
      let path = this.readUrl();
      if (path !== "/") {
        path = "/" + path;
      } else {
        path = "/assets";
      }
      this.sendRequest(
        {
          display_name: folder,
          filename: filenameRegex(convertToSlug(folder)),
          is_dir: true,
          path: path,
        },
        true
      );
    }
  }

  protected renameFolder(id: number): void {
    const file = this.assets.filter((asset) => {
      return asset.id === id;
    });
    const name = file[0].display_name ? file[0].display_name : file[0].filename;
    const folder = prompt(
      "Please enter new folder name below",
      name ? name : "New Folder"
    );
    if (folder != null) {
      let path = this.readUrl();
      if (path !== "/") {
        path = "/" + path;
      } else {
        path = "/assets";
      }
      this.sendRenameRequest(
        {
          display_name: folder,
          filename: filenameRegex(convertToSlug(folder)),
          is_dir: true,
          path: path,
        },
        id
      );
    }
  }

  protected async sendRequest(reqObj: AssetModelRequest, final = false) {
    await this.postNew(reqObj, final);
  }

  protected async sendRenameRequest(
    reqObj: AssetModelRequest,
    id: number,
    showToast = true
  ) {
    await this.saveExisting(reqObj, id, showToast);
  }
}
