




















































































































































































import { Component } from "vue-property-decorator";
import draggable from "vuedraggable";
import AssetMenu from "@/components/asset/AssetMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";
import moment from "moment";
import { getImage, fileTypeDisplay } from "@/utility/helpers";

@Component({
  components: {
    draggable,
    AssetMenu,
    Icon
  }
})
export default class AssetTable extends Table {
  protected moment = moment;
  protected hideImages = false;
  protected hideDocs = false;
  protected hideDirs = false;
  protected getImage = getImage;
  protected fileTypeDisplay = fileTypeDisplay;
  created() {
    this.sort.value = "file_type";
  }

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.hide) {
        let hide = [];
        if (
          this.$route.query.hide &&
          typeof this.$route.query.hide === "string"
        ) {
          hide = [this.$route.query.hide];
        } else {
          hide = this.$route.query.hide as string[];
        }
        if (hide.length > 0) {
          hide.forEach(item => {
            if (item === "img") {
              (this.$refs.inputHideImages as HTMLInputElement).checked = true;
              this.hideImages = true;
            } else if (item === "doc") {
              (this.$refs.inputHideDocs as HTMLInputElement).checked = true;
              this.hideDocs = true;
            } else {
              (this.$refs.inputHideDirs as HTMLInputElement).checked = true;
              this.hideDirs = true;
            }
          });
        }
      }
    });
  }

  protected rename(id: number): void {
    this.$emit("renameFolder", id);
  }

  public hideTableImages(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputHideImages as HTMLInputElement).checked) {
      this.hideImages = true;
    } else {
      this.hideImages = false;
    }
    this.getAssetCheckboxInfo();
  }

  public hideTableDocs(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputHideDocs as HTMLInputElement).checked) {
      this.hideDocs = true;
    } else {
      this.hideDocs = false;
    }
    this.getAssetCheckboxInfo();
  }

  public hideTableDirs(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputHideDirs as HTMLInputElement).checked) {
      this.hideDirs = true;
    } else {
      this.hideDirs = false;
    }
    this.getAssetCheckboxInfo();
  }

  protected getAssetCheckboxInfo(): void {
    const query: string[] = [];
    if (this.hideImages) {
      query.push("img");
    }
    if (this.hideDocs) {
      query.push("doc");
    }
    if (this.hideDirs) {
      query.push("dir");
    }
    if (query.length > 0) {
      this.$router.push({ query: { ...this.$route.query, hide: query } });
    } else {
      if (this.$route.query.hide) {
        const newObj = { ...this.$route.query };
        delete newObj.hide;
        this.$router.push({ query: { ...newObj } });
      }
    }
  }
}
